import React from "react";
import { BsInbox } from "react-icons/bs";
import { MdSend } from "react-icons/md";
import classes from "./NewsLetter.module.css";
function NewsLetter() {
  return (
    <div className={classes.container}>
      <div className={classes.left_wrapper}>
        <div className={classes.big_icon}>
          <i>
            <BsInbox />
          </i>
        </div>

        <div>
          <h2>Get Freshly Baked Marketing Tips</h2>
          <p>
            Explore piping hot marketing insights served fresh! Uncover the
            latest, expertly baked tips to elevate your strategy and boost your
            success.
          </p>
        </div>
      </div>

      <div className={classes.input_section}>
        <div className={classes.input_wrapper}>
          <input type="text" placeholder="Enter your email" />
          <button className={classes.submit_btn}>
            <MdSend />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
