import React, { Children, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthWrapperTypes } from '../../types/types'

const  AuthWrapper: React.FC<AuthWrapperTypes> = ({children})  => {

  const isTokenExpired = (token: any) => {
    if(!token) return 
    const payload = JSON.parse(atob(token.split(".")[1]));
    return Math.floor(Date.now() / 1000) >= payload.exp;
  };

  // Example usage:
  const token = localStorage.getItem("token");
  const expired = isTokenExpired(token);


  const navigate = useNavigate();
  useEffect(() => {
    if(expired){
      alert("Session has expired")
    }
    if (expired || !token || token.length < 1) {
      navigate("/rider");
    }
  }, [expired]);

  return (
    <div>{children}</div>
  )
}

export default AuthWrapper