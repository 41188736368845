import PagesTitleCol from "../components/cols/PagesTitleCol";
import Body from "../components/Wrapper/Body";
import classes from "../Styles/Privacy.module.css";
import NewsLetter from "../components/cols/NewsLetter";
import { Helmet } from "react-helmet";

function Privacy() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <Body>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="At Sprinterwave, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our app. By accessing or using Sprinterwave, you agree to the terms of this Privacy Policy."
        />
        <meta
          name="keywords"
          content="Privacy Policy, Safety, Bill payment, Ticket booking, Logistics solutions, Financial management, Mobile payments, Event tickets, Transport services, Mobile app, Payment app, Bill management, Logistics app, Financial transactions, Ticket reservations, Digital payments, Mobile commerce, Convenience app, Utility payments, Event planning"
        ></meta>

        <link rel="canonical" href={"https://sprinterwave.com/contact"} />
      </Helmet>

      <PagesTitleCol
        description="PRIVACY POLICY
        "
        title="Last updated June 08, 2024"
        bg_imageName="privacy"
      />

      <main className={classes.main}>
        {/* <h1>PRIVACY POLICY</h1> */}
        <p>
          {" "}
          This privacy notice for Sprinterwave Technologies ('we', 'us', or
          'our'), describes how and why we might collect, store, use, and/or
          share ('process') your information when you use our services
          ('Services'), such as when you:
        </p>
        <ul>
          <li>
            Download and use our mobile application (Sprinterwave), or any other
            application of ours that links to this privacy notice
          </li>
          <li>
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
        <p>
          Questions or concerns? Reading this privacy notice will help you
          understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you
          still have any questions or concerns, please contact us at
          support@sprinterwave.com.
        </p>
        <h4>SUMMARY OF KEY POINTS</h4>
        <p>
          <b>
            <i>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our <a href="#table_of_content">table of
              contents</a>  below to find the section you are looking for.
            </i>
          </b>
        </p>
        <p>
          <b>What personal information do we process? </b> When you visit, use,
          or navigate our Services, we may process personal information
          depending on how you interact with us and the Services, the choices
          you make, and the products and features you use. Learn more about
          <a href="#personel_info"> personal information you disclose to us</a>.
        </p>
        <p>
          <b>Do we process any sensitive personal information? </b>
          We do not process sensitive personal information.
        </p>{" "}
        <p>
          <b>Do we collect any information from third parties? </b>
          We do not collect any information from third parties.
        </p>
        <p>
          <b>How do we process your information? </b> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about <a href="#how_we_process_ur_info">how we process your
          information</a>.
        </p>
        <p>
          <b>
            In what situations and with which parties do we share personal
            information?
          </b>{" "}
          We may share information in specific situations and with specific
          third parties. Learn more about <a href="#whoom_we_share_your">when and with whom we share your
          personal information</a>.
        </p>
        <p>
          <b>How do we keep your information safe?</b> {" "}We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <a href="#how_wee_kee_info">how we keep your information safe</a>.
        </p>

<p>
   <b>
   What are your rights?{" "}
   </b>
   Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#your_privacy_rights">your privacy rights</a>.

</p>

<p>
   <b>
   How do you exercise your rights? {" "}
   </b>
   The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.

</p>

<p>
   <b>
   Want to learn more about what we do with any information we collect?
   {" "}
   </b>
   <a href="#personel_info">
   Review the privacy notice in full.
   </a>
</p>

<br />
<br />
        <h4 id="table_of_content">TABLE OF CONTENTS</h4>
        <p>
          <a href="#personel_info">1. WHAT INFORMATION DO WE COLLECT?</a>
        </p>
        <p>
          <a href="#how_we_process_ur_info">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </p>
        <p>
          <a href="#whoom_we_share_your">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
        </p>
        <p>
          <a href="#HOW_LONG_DO_WE_KEEP_YOUR_INFORMATION">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </p>
        <p>
          <a href="#how_wee_kee_info">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </p>
        <p>
          <a href="#your_privacy_rights">7. WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </p>
        <p>
          <a href="#CONTROLS_FOR_DO-NOT-TRACK_FEATURES">8. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </p>
        <p>
          <a href="#DO_WE_MAKE_UPDATES_TO_THIS_NOTICE">9. DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </p>
        <p>
          <a href="#HOW_CAN_YOU_CONTACT_US_ABOUT_THIS_NOTICE">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </p>
        <p>
          <a href="#HOW_CAN_YOU_REVIEW_UPDATE_OR_DELETE_THE_DATA_WE_COLLECT_FROM_YOU">
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </p>

        <br />
        <h4 id="personel_info">1. WHAT INFORMATION DO WE COLLECT?</h4>
        <h6>Personal information you disclose to us</h6>
        <p>
          <b>In short:</b> We collect personal information that you provide to
          us.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          Personal Information Provided by You. The personal information that we
          collect depends on the context of your interactions with us and the
          Services, the choices you make, and the products and features you use.
          The personal information we collect may include the following:
        </p>
        <ul>
          <li>phone numbers</li>
          <li>names</li>
          <li>email addresses</li>
          <li>mailing addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
          <li>billing addresses</li>
        </ul>
        <p>
          <b>Sensitive Information.</b>We do not process sensitive information.
        </p>
        <p>
          <b>Payment Data</b>. We may collect data necessary to process your
          payment if you choose to make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is handled and stored by Monnify. You may
          find their privacy notice link(s) here:
          <a href="https://monnify.com/terms.html">
            https://monnify.com/terms.html
          </a>
          .
        </p>
        <p>
          <b>Application Data</b> If you use our application(s), we also may
          collect the following information if you choose to provide us with
          access or permission:
        </p>
        <ul>
          <li>
            Geolocation Information. We may request access or permission to
            track location-based information from your mobile device, either
            continuously or while you are using our mobile application(s), to
            provide certain location-based services. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </li>
          <li>
            Mobile Device Access. We may request access or permission to certain
            features from your mobile device, including your mobile device's
            contacts, camera, storage, and other features. If you wish to change
            our access or permissions, you may do so in your device's settings.
          </li>

          <li>
            Mobile Device Data. We automatically collect device information
            (such as your mobile device ID, model, and manufacturer), operating
            system, version information and system configuration information,
            device and application identification numbers, browser type and
            version, hardware model Internet service provider and/or mobile
            carrier, and Internet Protocol (IP) address (or proxy server). If
            you are using our application(s), we may also collect information
            about the phone network associated with your mobile device, your
            mobile device's operating system or platform, the type of mobile
            device you use, your mobile device's unique device ID, and
            information about the features of our application(s) you accessed.
          </li>
          <li>
            Push Notifications. We may request to send you push notifications
            regarding your account or certain features of the application(s). If
            you wish to opt out from receiving these types of communications,
            you may turn them off in your device's settings.
          </li>
        </ul>
        <p>
          This information is primarily needed to maintain the security and
          operation of our application(s), for troubleshooting, and for our
          internal analytics and reporting purposes.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h4>Information automatically collected</h4>
        <p>
          <b>In Short:</b> Some information—such as your Internet Protocol (IP)
          address and/or browser and device characteristics — is collected
          automatically when you visit our Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            Device Data. We collect device data such as information about your
            computer, phone, tablet, or other device you use to access the
            Services. Depending on the device used, this device data may include
            information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware
            model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.
          </li>

          <li>
            Location Data. We collect location data such as information about
            your device's location, which can be either precise or imprecise.
            How much information we collect depends on the type and settings of
            the device you use to access the Services. For example, we may use
            GPS and other technologies to collect geolocation data that tells us
            your current location (based on your IP address). You can opt out of
            allowing us to collect this information either by refusing access to
            the information or by disabling your Location setting on your
            device. However, if you choose to opt out, you may not be able to
            use certain aspects of the Services.
          </li>
        </ul>
        <h4 id="how_we_process_ur_info">2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
        <p>
          <b>In Short:</b> We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </p>
        <ul>
          <li>
            To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </li>
          <li>
            To deliver and facilitate delivery of services to the user. We may
            process your information to provide you with the requested service.
          </li>
          <li>
            To respond to user inquiries/offer support to users. We may process
            your information to respond to your inquiries and solve any
            potential issues you might have with the requested service.
          </li>
          <li>
            To send administrative information to you. We may process your
            information to send you details about our products and services,
            changes to our terms and policies, and other similar information.
          </li>
          <li>
            To fulfil and manage your orders. We may process your information to
            fulfil and manage your orders, payments, returns, and exchanges made
            through the Services.
          </li>
          <li>
            To post testimonials. We post testimonials on our Services that may
            contain personal information.
          </li>
          <li>
            To protect our Services. We may process your information as part of
            our efforts to keep our Services safe and secure, including fraud
            monitoring and prevention.
          </li>
        </ul>
        <h4 id="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
        <p>
          <b>In Short:</b> We may share information in specific situations
          described in this section and/or with the following third parties.
        </p>
        <p>
          We may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li>
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>
            When we use Google Maps Platform APIs. We may share your information
            with certain Google Maps Platform APIs (e.g. Google Maps API, Places
            API). We obtain and store on your device ('cache') your location.
            You may revoke your consent anytime by contacting us at the contact
            details provided at the end of this document.
          </li>
        </ul>
        <h4 id="whoom_we_share_your">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
        <p>
          <b>In Short:</b> We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to gather information when you interact with our Services.
          Some online tracking technologies help us maintain the security of our
          Services and your account, prevent crashes, fix bugs, save your
          preferences, and assist with basic site functions.
        </p>
        <p>
          We also permit third parties and service providers to use online
          tracking technologies on our Services for analytics and advertising,
          including to help manage and display advertisements, to tailor
          advertisements to your interests, or to send abandoned shopping cart
          reminders (depending on your communication preferences). The third
          parties and service providers use their technology to provide
          advertising about products and services tailored to your interests
          which may appear either on our Services or on other websites.
        </p>
        <p>
          Specific information about how we use such technologies and how you
          can refuse certain cookies is set out in our Cookie Notice.
        </p>
        <h4 id="HOW_LONG_DO_WE_KEEP_YOUR_INFORMATION">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
        <p>
          <b>In short:</b> We keep your information for as long as necessary to
          fulfil the purposes outlined in this privacy notice unless otherwise
          required by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymise such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h4 id="how_wee_kee_info">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
        <p>
          <b>In short:</b> We aim to protect your personal information through a
          system of organisational and technical security measures. We have
          implemented appropriate and reasonable technical and organisational
          security measures designed to protect the security of any personal
          information we process. However, despite our safeguards and efforts to
          secure your information, no electronic transmission over the Internet
          or information storage technology can be guaranteed to be 100% secure,
          so we cannot promise or guarantee that hackers, cybercriminals, or
          other unauthorised third parties will not be able to defeat our
          security and improperly collect, access, steal, or modify your
          information. Although we will do our best to protect your personal
          information, transmission of personal information to and from our
          Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h4 id="your_privacy_rights">7. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
        <p>
          <b>In Short:</b> You may review, change, or terminate your account at
          any time, depending on your country, province, or state of residence.
        </p>
        <p>
          <u>Withdrawing your consent</u>: If we are relying on your consent to process
          your personal information, which may be express and/or implied consent
          depending on the applicable law, you have the right to withdraw your
          consent at any time. You can withdraw your consent at any time by
          contacting us by using the contact details provided in the section '{" "}
          <a style={{ color: "#1498dd" }} href="#how_can">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>{" "}
          ' below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <h4>Account Information</h4>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at
          <a href="mailto:support@sprinterwave.com">support@sprinterwave.com</a>
          .
        </p>
        <h4 id="CONTROLS_FOR_DO-NOT-TRACK_FEATURES">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ('DNT') feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognising and implementing DNT
          signals has been finalised. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h4 id="DO_WE_MAKE_UPDATES_TO_THIS_NOTICE">9. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
        <p id="how_can">
          <b></b> Yes, we will update this notice as necessary to stay compliant
          with relevant laws.
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date at the top of
          this privacy notice. If we make material changes to this privacy
          notice, we may notify you either by prominently posting a notice of
          such changes or by directly sending you a notification. We encourage
          you to review this privacy notice frequently to be informed of how we
          are protecting your information.
        </p>
        <h4 id="HOW_CAN_YOU_CONTACT_US_ABOUT_THIS_NOTICE">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
        <p>
          If you have questions or comments about this notice, you may email us
          at
          <a href="mailto:support@sprinterwave.com">
            support@sprinterwave.com
          </a>{" "}
          or contact us by post at:
        </p>
        <p>
          Sprinterwave Technologies <br />
          Agip
          <br />
          Port Harcourt, RIVERS 500001
          <br />
          Nigeria
        </p>
        <h4 id="HOW_CAN_YOU_REVIEW_UPDATE_OR_DELETE_THE_DATA_WE_COLLECT_FROM_YOU">
          11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h4>
        <p>
          You have the right to request access to the personal information we
          collect from you, details about how we have processed it, correct
          inaccuracies, or delete your personal information. You may also have
          the right to withdraw your consent to our processing of your personal
          information. These rights may be limited in some circumstances by
          applicable law. To request to review, update, or delete your personal
          information, please fill out and submit a{" "}
          <a href="https://www.sprinterwave.com/contact">
            data subject access request
          </a>{" "}
          .
        </p>
      </main>

      <NewsLetter />
    </Body>
  );
}

export default Privacy;
