import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { usePostRequestMutation } from "../../store/services/users";
import classes from "./Styles/Section1styles.module.css";
const RiderSection1 = () => {
  const [Register, {}] = usePostRequestMutation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");

  let [enteredData, setEnteredData] = useState({
    email: "",
    password: "",
  });

  const [showForm, setShowForn] = useState<boolean>(false);
  const [showVerifyForm, setShowVerifyForm] = useState<boolean>(false);

  const toggleLogin = () => {
    setShowForn(!showForm);
  };
  const toggleVerificationForm = () => {
    setShowVerifyForm(!showVerifyForm);
  };
  const onChangeHandler = (element: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredData((prevState) => {
      return {
        ...prevState,
        [element.target.name]: element.target.value,
      };
    });
  };

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Register({
      url: "/login",
      body: { ...enteredData },
    })
      .unwrap()
      .then((data) => {
        onRequestCodeHandler();
        toggleVerificationForm();
      })
      .catch((error) => {
        console.log("Error", error.data.message);
        alert(error.data.message);
      });
  };

  // Re generate code
  const onRequestCodeHandler = () => {
    Register({
      url: "/generate-confirmation-code",
      body: {
        emailType: "verify-email",
        email: enteredData.email,
      },
    })
      .unwrap()
      .then((data) => {
        alert("Code sent");
      })
      .catch((error) => {
        console.log("Error", error.data.message);
        alert(error.data.message);
      });
  };

  const onVerifyhandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Register({
      url: "/acccount-verification-verify-code",
      body: {
        email: enteredData.email,
        code: code,
      },
    })
      .unwrap()
      .then((data) => {
        localStorage.setItem("token", data.user.token);
        navigate("/rider-application");
      })
      .catch((error) => {
        console.log("Error", error.data.message);
        alert(error.data.message);
      });
  };
  return (
    <>
      <section className={classes.section + " " + classes.noBg}>
        <div className={classes.details}>
          <div className={classes.name}>
            <h1>
              Maximize Your Earnings as a SprinterWave Rider: Join Us Today!
            </h1>
          </div>

          <div className={classes.stack}>
            <p>
              Are you aware of the lucrative opportunities available as a
              SprinterWave rider? Not only do you receive a generous percentage
              for each delivery, but we also reward you with a bonus for every
              task you complete successfully.
              <br />
              <br />
              Join our team and benefit from competitive pay rates, where your
              earnings grow with each delivery you make. We value our riders and
              believe in rewarding hard work and dedication. Whether you’re
              looking for a steady income or a flexible job to suit your
              schedule, being a SprinterWave rider can be a highly rewarding
              choice. Sign up today and start maximizing your earnings with each
              trip and bonus you earn!
            </p>

            <div className={classes.apply_btn}>
              <button onClick={toggleLogin}>Start Riding</button>
            </div>
          </div>
        </div>

        <div className={classes.left_image}>
          <img
            src="https://images.pexels.com/photos/8027615/pexels-photo-8027615.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load"
            alt=""
          />
        </div>

        {showForm && (
          <div className={classes.rider_login_wrapper}>
            <div className={classes.login_wrapper}>
              <div>
                <div className={classes.row}>
                  <h1>Customer login</h1>
                  <FaTimes size={25} onClick={() => setShowForn(false)} />
                </div>{" "}
                <p>
                  Please sign in using your regular customer account here. We
                  will then start setting up your rider account. Please be aware
                  that you must be an existing customer to qualify for rider
                  status.{" "}
                </p>
                <form action="" onSubmit={onSubmitHandler}>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    onChange={onChangeHandler}
                    name={"email"}
                    required
                  />
                  <br />
                  <input
                    type="password"
                    placeholder="Enter password"
                    name={"password"}
                    onChange={onChangeHandler}
                    required
                  />
                  <br />
                  <input type="submit" value="Login" />
                </form>{" "}
              </div>
            </div>
          </div>
        )}

        {showVerifyForm && (
          <div className={classes.rider_login_wrapper}>
            <div className={classes.login_wrapper}>
              <div>
                <div className={classes.row}>
                  <h1>Verify Account</h1>
                  <FaTimes size={25} onClick={toggleVerificationForm} />
                </div>{" "}
                <p>Please check yoru email a verification code was sent.</p>
                <form action="" onSubmit={onVerifyhandler}>
                  <input
                    type="text"
                    placeholder="Enter code"
                    value={code}
                    onChange={(el: React.ChangeEvent<HTMLInputElement>) =>
                      setCode(el.target.value)
                    }
                    name={"code"}
                    required
                  />

                  <br />
                  <p className={classes.request_code_style} onClick={onRequestCodeHandler}>Didn't get the code?</p>
                  <input type="submit" value="Verify" />
                </form>{" "}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default RiderSection1;
