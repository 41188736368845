import React from "react";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsPhone,
  BsTwitter,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import classes from "./FooterStyles.module.css";
const Footer = () => {
  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.about}>
          <h1>Sprinterwave</h1>
          <p>
            Sprinter is available to assist you. Should you require support,
            feel free to connect with us through any of our social media
            channels. We're always open for conversation and ready to help, any
            time you need
          </p>
          <a href="https://web.facebook.com/sprinterwave" target={"_blank"}>
            <i>
              <BsFacebook />
            </i>
          </a>
          <a href="https://twitter.com/thesprinterwave" target={"_blank"}>
            <i>
              <BsTwitter />
            </i>
          </a>
          <a href="https://www.instagram.com/sprinterwave/" target={"_blank"}>
            <i>
              <BsInstagram />
            </i>
          </a>
          <a href="https://www.linkedin.com/company/sprinterwave/" target={"_blank"}>
            <i>
              <BsLinkedin />
            </i>
          </a>
        </div>

        <div className={classes.contact}>
          <h3>Company</h3>

          <div className={classes.contact_list}>
            <i>
              <BsPhone />
            </i>

            <h3>+234 8080025944</h3>
          </div>

          <div className={classes.contact_list}>
            <i>
              <MdEmail />
            </i>

            <h3>sprinterwave@gmail.com</h3>
          </div>
        </div>
      </footer>
      <br />
      <p className={classes.btn_txt}>
        Sprinterwave, All rights reserved, Product by Gabriel Delight CEO.
      </p>
    </>
  );
};

export default Footer;
