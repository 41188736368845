import Home from "./pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "./pages/About";
import Page404 from "./pages/Page404";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Rider from "./pages/Rider";
import RiderApplication from "./pages/RiderApplication";
import Privacy from "./pages/Privacy";
import TermsAndConditions from "./pages/TermsAndConditions";
const App = () => {
  const location = useLocation();
  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/rider" element={<Rider />} />
        <Route path="/rider-application" element={<RiderApplication />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </>
  );
};

export default App;
