import React from "react";
import { Link } from "react-router-dom";
import Body from "../components/Wrapper/Body";
import classes from "../Styles/404.module.css";
const Page404 = () => {
  return (
    <Body>
      <div className={classes.container}>
        <h1>404</h1>
        <h3>We're sorry, but the page you're looking for cannot be found</h3>
        <h3>
          Please check the URL or try searching for the content you're looking
          for.
        </h3>
        <Link to={"/?target=scroll"}>
          <button>Contact me</button>
        </Link>
      </div>
    </Body>
  );
};

export default Page404;
