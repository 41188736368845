import React from "react";
import PagesTitleCol from "../components/cols/PagesTitleCol";
import RiderSection1 from "../components/Sections/RiderSection1";
import Body from "../components/Wrapper/Body";

export default function Rider() {
  return (
    <Body>
      {/* <PagesTitleCol
        description="Become a rider with Sprinterwave"
        title="Rider"
        bg_imageName="rider"
      /> */}

      <RiderSection1 />
    </Body>
  );
}
