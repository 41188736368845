export const faq = [
   {
       "title": "Can I Access Financial Reports and Transaction History?",
       "description": "Explore how to access and manage your financial reports and transaction history within the app. Discover how to view, download, or export this information for your records."
   },
   {
       "title": "What Payment Methods Do You Accept?",
       "description": "Find details about the different payment methods supported by our app. Get information on credit cards, digital wallets, bank transfers, and other forms of payment accepted."
   },
   {
       "title": "How Do I Purchase Tickets Through Your App?",
       "description": "Explore a step-by-step guide on how to browse, select, and purchase tickets for various events using our app. Learn about the ticketing process and available options."
   },
   {
       "title": "What Happens if an Event Is Canceled or Rescheduled?",
       "description": "Learn about the process and steps involved if an event is canceled or rescheduled. Understand your options, including refunds or ticket transfer possibilities."
   },
   {
       "title": "How Secure Are Transactions on Your Platform?",
       "description": "Learn about the robust security measures in place to ensure your financial transactions are safe and secure. Discover encryption methods and safety protocols implemented within our app."
   },
   {
       "title": "Do You Offer Support for Technical Issues or App Navigation?",
       "description": "Understand the available support for technical issues within the app, including navigation, features, and any troubleshooting assistance available. Learn how to contact our support team for assistance."
   },
   {
       "title": "How Does Your App Protect User Privacy?",
       "description": "Learn about the app's privacy policies and measures implemented to safeguard user data. Understand our commitment to protecting your privacy and the steps taken to ensure data security."
   },
   {
       "title": "Can I Cancel or Modify My Ticket Reservation?",
       "description": "Discover the policies and procedures for canceling or modifying event ticket reservations. Understand the terms for refunds or changes to booked tickets."
   },
   {
       "title": "What Happens in Case of Payment Disputes or Issues?",
       "description": "Understand the procedures and support available in case of payment disputes or issues with transactions. Discover how to contact our support team and steps to resolve any concerns."
   },
   {
       "title": "How Do I Access Event Details and Location Information?",
       "description": "Find out how to access comprehensive event information, including event details, venue location, timings, and additional event-specific information available in the app."
   }
]
