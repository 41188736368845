import React from "react";
import BlogPagesTitleBox from "../components/cols/BlogPagesTitleBox";
import NewsLetter from "../components/cols/NewsLetter";
import Body from "../components/Wrapper/Body";
import classes from "../Styles/Blog.module.css";
import { Helmet } from "react-helmet";

function Blog() {
  let data = [
    {
      image:
        "https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Blog06-1536x960.jpg",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
    {
      image:
        "https://images.pexels.com/photos/4468146/pexels-photo-4468146.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
    {
      image:
        "https://images.pexels.com/photos/3760096/pexels-photo-3760096.jpeg?auto=compress&cs=tinysrgb&w=1600",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
    {
      image:
        "https://images.pexels.com/photos/4050468/pexels-photo-4050468.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
  ];

  let suggestedData = [
    {
      image:
        "https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Blog06-1536x960.jpg",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
    {
      image:
        "https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Blog06-1536x960.jpg",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
    {
      image:
        "https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Blog06-1536x960.jpg",
      description:
        "The Intersection Of Finance And Technology: Key Trends And Developments",
      date: "April 5, 2023",
    },
  ];

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <Body>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Blog | Sprinterwave </title>
        <meta
          name="description"
          content="Discover insightful articles on financial management, bill tracking, event logistics, and more. Our blog provides expert tips and guidance to streamline your financial journey and maximize the use of our all-in-one app."
        />
        <meta
          name="keywords"
          content="Transaction processing, Bill payment, Ticket booking, Logistics solutions, Financial management, Mobile payments, Event tickets, Transport services, Mobile app, Payment app, Bill management, Logistics app, Financial transactions, Ticket reservations, Digital payments, Mobile commerce, Convenience app, Utility payments, Event planning"
        ></meta>

        <link rel="canonical" href={"https://sprinterwave.com/blog"} />
      </Helmet>

      <BlogPagesTitleBox />

      <main className={classes.main}>
        <article>
          <h1>Read Best Blogs</h1>

          <div className={classes.post_wrapper}>
            {data.map((cur) => {
              return (
                <div className={classes.post_list}>
                  <img src={cur.image} />
                  <p className={classes.description}>{cur.description}</p>
                  <p className={classes.date}>{cur.date}</p>
                </div>
              );
            })}
          </div>
        </article>
        <aside>
          <h1>Recent Posts</h1>

          <div className={classes.suggested_wrapper}>
            {suggestedData.map((cur) => {
              return (
                <div className={classes.suggested_container}>
                  <img src={cur.image} alt="" />
                  <div>
                    <p className={classes.description_2}>{cur.description}</p>
                    <p className={classes.date}>{cur.date}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={classes.subscribe}>
            <h3>Subscribe To Newsletter</h3>
            <p>
            Subscribe to our newsletter for exclusive updates, financial tips, app feature highlights, and special offers.
            </p>

            <a href="#subscribe_div">
              <button>Subscribe</button>
            </a>
          </div>
        </aside>
      </main>

      <div id="subscribe_div">
        <NewsLetter />
      </div>
    </Body>
  );
}

export default Blog;
