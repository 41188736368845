import React from "react";
import Col1_about from "../components/cols/Col1_about";
import Col3 from "../components/cols/Col3";
import PagesTitleCol from "../components/cols/PagesTitleCol";
import StatsCounter from "../components/cols/StatsCounter";
import Body from "../components/Wrapper/Body";
import { Helmet } from "react-helmet";

const About: React.FC = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  return (
    <Body>
         <Helmet>
        <meta charSet="utf-8" />
        <title>About | Sprinterwave </title>
        <meta
          name="description"
          content="Take charge of your finances and logistics effortlessly with our all-in-one app. Manage bills, purchase airtime, data, event tickets, and more with ease. Simplify your life with our secure and user-friendly app."
        />
        <meta
          name="keywords"
          content="Transaction processing, Bill payment, Ticket booking, Logistics solutions, Financial management, Mobile payments, Event tickets, Transport services, Mobile app, Payment app, Bill management, Logistics app, Financial transactions, Ticket reservations, Digital payments, Mobile commerce, Convenience app, Utility payments, Event planning"
        ></meta>

        <link rel="canonical" href={"https://sprinterwave.com/about"} />
      </Helmet>

      <PagesTitleCol
        description="Sprinterwave Is The Future Of Payment"
        title="About"
        bg_imageName="about"
      />

      <Col1_about />
      <hr className="hr_line" />
      <br />
      <Col3 />
      <StatsCounter />
      <br />
      <br />
      <br />
    </Body>
  );
};

export default About;
