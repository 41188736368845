import React from "react";
import classes from "./PagesTitleCol.module.css";

interface Props {
  title: string;
  description: string;
  bg_imageName: string
}

const PagesTitleCol: React.FC<Props> = ({ description, title, bg_imageName}) => {

   let classNaames = classes.container
   if(bg_imageName === "contact") classNaames = classes.contact_container
   if(bg_imageName === "about") classNaames=  classes.about_background_image
   if(bg_imageName === "rider") classNaames=  classes.rider_background_image
   if(bg_imageName === "privacy") classNaames=  classes.privacy_background_image
   if(bg_imageName === "terms") classNaames=  classes.terms_background_image
  return (
    <div className={classNaames}>
      <div className={classes.wrapper}>
        {" "}
        <p>{title}</p>
        <h1>{description}</h1>
      </div>
    </div>
  );
};

export default PagesTitleCol;
