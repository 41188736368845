import React from "react";
import { MdCall, MdEmail } from "react-icons/md";
import PagesTitleCol from "../components/cols/PagesTitleCol";
import Body from "../components/Wrapper/Body";
import classes from "../Styles/Contact.module.css";
import classesColStyles from "../components/cols/ColsStyles.module.css";
import { FaMapMarker } from "react-icons/fa";
import NewsLetter from "../components/cols/NewsLetter";
import Faq from "../components/FAQ/Faq";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from 'sweetalert2'

function ContactUs() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const COntactSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your last name"),
    lastname: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please eneter your last name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Your valid email is required"),
    subject: Yup.string()
      .min(2, "Too Short!")
      .max(190, "Too Long!")
      .required("Subject is required"),

    message: Yup.string()
      .min(2, "Too Short!")
      .max(1000, "Too Long!")
      .required("Subject is required"),
  });

  return (
    <Body>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact | Sprinterwave </title>
        <meta
          name="description"
          content="Need to get in touch? We're here for you! Reach us easily through our contact details for any queries or assistance. Your feedback matters, and we're ready to assist."
        />
        <meta
          name="keywords"
          content="Transaction processing, Bill payment, Ticket booking, Logistics solutions, Financial management, Mobile payments, Event tickets, Transport services, Mobile app, Payment app, Bill management, Logistics app, Financial transactions, Ticket reservations, Digital payments, Mobile commerce, Convenience app, Utility payments, Event planning"
        ></meta>

        <link rel="canonical" href={"https://sprinterwave.com/contact"} />
      </Helmet>

      <PagesTitleCol
        description="Contact us anytime"
        title="Contact Us"
        bg_imageName="contact"
      />

      <main className={classes.main}>
        <div className={classes.left_container}>
          <div className={classesColStyles.data} id="contact_us">
            <h2>Contact Information</h2>

            <p>
            Need to get in touch? We're here for you! Reach us easily through our contact details for any queries or assistance. Your feedback matters, and we're ready to assist.
            </p>

            <div
              className={
                classesColStyles.flex_btm_box +
                " " +
                classesColStyles.col_formart
              }
            >
              <div
                className={
                  classesColStyles.box_1 + " " + classesColStyles.row_format
                }
              >
                <div className={classesColStyles.icon_wrapper}>
                  <i>
                    <MdCall />
                  </i>
                </div>

                <div>
                  <h3>Call our support center</h3>
                  <p>+234 8080025944</p>
                </div>
              </div>

              <div
                className={
                  classesColStyles.box_1 + " " + classesColStyles.row_format
                }
              >
                <div className={classesColStyles.icon_wrapper}>
                  <i style={{ backgroundColor: "#1498dd" }}>
                    <MdEmail />
                  </i>
                </div>
                <div>
                  <h3>Email Us</h3>
                  <p>sprinterwave@gmail.com</p>
                </div>
              </div>

              <div
                className={
                  classesColStyles.box_1 + " " + classesColStyles.row_format
                }
              >
                <div className={classesColStyles.icon_wrapper}>
                  <i>
                    <FaMapMarker />
                  </i>
                </div>
                <div>
                  <h3>HQ Location</h3>
                  <p> Port Harcourt, Nigeria.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.right_container}>
          <h2>Send Us Your Query Here</h2>

          <p>Let us know how to get back to you.</p>

          <Formik
            initialValues={{
              firstname: "",
              lastname: "",
              subject: "",
              email: "",
              message: "",
            }}
            onSubmit={(values) => {
              Swal.fire(
                'Message received!',
                'Your message has been submitted and we;ll get back to your via your email addressYour message has been successfully submitted. We will respond to your email address as soon as we can.',
                'success'
              )
            }}
            validationSchema={COntactSchema}
          >
            {({
              handleChange,
              handleSubmit,
              setFieldTouched,
              touched,
              isValid,
              values,
              errors,
            }) => (
              <>
                {" "}
                <div className={classes.form_wrapper}>
                  <div className={classes.input_wrapper}>
                    <label htmlFor="firstname">First Name *</label>
                    <input
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange("firstname")}
                    />

                    {touched.firstname && errors.firstname && (
                      <p className={classes.error}>{errors.firstname}</p>
                    )}
                  </div>
                  <div className={classes.input_wrapper}>
                    <label htmlFor="">Last Name *</label>
                    <input
                      type="text"
                      name="lastname"
                      id=""
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange("lastname")}
                    />

                    {touched.lastname && errors.lastname && (
                      <p className={classes.error}>{errors.lastname}</p>
                    )}
                  </div>
                </div>
                <div className={classes.form_wrapper}>
                  <div className={classes.input_wrapper}>
                    <label htmlFor="">Email Address *</label>
                    <input
                      type="email"
                      name="email"
                      id=""
                      placeholder="example@mail.com"
                      value={values.email}
                      onChange={handleChange("email")}
                    />

                    {touched.email && errors.email && (
                      <p className={classes.error}>{errors.email}</p>
                    )}
                  </div>
                  <div className={classes.input_wrapper}>
                    <label htmlFor="">Subject *</label>
                    <input
                      type="text"
                      name="subject"
                      id=""
                      placeholder="Issue Here"
                      value={values.subject}
                      onChange={handleChange("subject")}
                    />

                    {touched.subject && errors.subject && (
                      <p className={classes.error}>{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className={classes.text_area_section}>
                  <h2>How Can We Help?</h2>
                  <p>Feel free to ask a question or simply leave a comment</p>
                  <br />
                  <label htmlFor="">Comments / Questions *</label>

                  <textarea
                    name="message"
                    placeholder="Add text"
                    value={values.message}
                    onChange={handleChange("message")}
                  ></textarea>

                  {touched.message && errors.message && (
                    <p className={classes.error}>{errors.message}</p>
                  )}

                  <button onClick={() => handleSubmit()}>Send Message</button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </main>

      <br />
      <Faq />

      <NewsLetter />
    </Body>
  );
}

export default ContactUs;
