import React, { useEffect, useState } from "react";
import AuthWrapper from "../components/AuthWrapper/AuthWrapper";
import classes from "../Styles/RiderApplication.module.css";
import { FaImage, FaPowerOff, FaTimes } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import PagesTitleCol from "../components/cols/PagesTitleCol";
import {
  useGetRequestQuery,
  useUploadFileMutation,
} from "../store/services/users";
import Swal from "sweetalert2";
import { RiderImageURLDocumentPath } from "../ImageUrl/ImageUrl";
import { Link } from "react-router-dom";

export default function RiderApplication() {
  const [PostRequest, { isLoading }] = useUploadFileMutation();
  const [image, setImage] = useState<string>("");
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [enteredLocation, setEnteredLocation] = useState<string>("");
  const [imageBlob, setImageBlob] = useState<any>();
  const [documentName, setDocumentName] = useState<string>("");

  const { data: rider } = useGetRequestQuery("/rider");

  console.log("rider", rider);

  const onCreateHandler = () => {
    PostRequest({
      url: "/rider",
    })
      .unwrap()
      .then((data) => {
        Swal.fire(
          "Rider Account Created!",
          "Congratulations! Your rider account has been successfully created. You may now proceed to upload the requested document for your final approval",
          "success"
        );
      })
      .catch((error) => {
        console.log(error.data.message);
        Swal.fire(error.data.message);
      });
  };

  const onSelectHandler = (el: React.ChangeEvent<HTMLSelectElement>) => {
    PostRequest({
      url: "/update-transport-type",
      body: {
        transportType: el.target.value,
      },
    })
      .unwrap()
      .then((data) => {
        Swal.fire("Transport type updated successfully!");
        setShowPreview(false);
      });
  };

  const handleChangeText = (el: React.ChangeEvent<HTMLInputElement>) => {
    setEnteredLocation(el.target.value);
  };

  console.log("enteredLocation", enteredLocation);

  useEffect(() => {
    let intervalId = setTimeout(() => {
      console.log(enteredLocation);

      if (enteredLocation === rider?.dirvingLocation) return;

      if (enteredLocation.length < 1) return;
      PostRequest({
        url: "/update-drivng-location",
        body: {
          dirvingLocation: enteredLocation,
        },
      })
        .unwrap()
        .then((data) => {
          Swal.fire("Driving location updated!");
          setShowPreview(false);
        })
        .catch((error) => console.log(error));
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [enteredLocation]);

  useEffect(() => {
    setEnteredLocation(rider?.dirvingLocation);
  }, [rider?.dirvingLocation]);

  const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    let fileName = value.target.name;
    let _img: any = value.target.files;

    let img__ = URL.createObjectURL(_img[0]);

    setImage(img__);
    setDocumentName(fileName);
    setImageBlob(_img[0]);

    setShowPreview(true);
  };

  const onUploadHandler = () => {
    const formData = new FormData();
    formData.append("upload", imageBlob);
    formData.append("documentName", documentName);
    PostRequest({
      url: "/document",
      body: formData,
    })
      .unwrap()
      .then((data) => {
        Swal.fire(
          "Document Upload!",
          "Your document has been uploaded and is now ready for review. Please be patient while it is being evaluated.",
          "success"
        );
        setShowPreview(false);
      })
      .catch((error) => console.log(error));
  };

  const onDeleteHandler = (_documentName: string, _image: string) => {
    let confirm_ = window.confirm(
      "Are you sure you want to delete this document from the review list?"
    );
    if (!confirm_) return;
    PostRequest({
      url: "/rider-delete-document",
      body: {
        documentName: _documentName,
        image: _image,
      },
    })
      .unwrap()
      .then((data) => {
        Swal.fire("Document Deleted!");
        setShowPreview(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <AuthWrapper>
      <PagesTitleCol
        title="Rider Document"
        description="Please provide the listed documents and information below to enable your driving with us."
        bg_imageName="rider"
      />
      <div className={classes.main}>
        {!rider ? (
          <div className={classes.account_}>
            <h1>Quick Account Setup with Existing Credentials</h1>
            <p>
              Creating an account is simple since you already have our customer
              application. Just tap the "Create" button below to set up your
              account using the credentials you initially used for the customer
              section of Sprinterwave.
            </p>
            {isLoading ? (
              <button>Please wait...</button>
            ) : (
              <button onClick={onCreateHandler}>Create</button>
            )}
          </div>
        ) : (
          <>
            {" "}
            <div className={classes.form_wrapper}>
              <div className={classes.account_}>
                <p>
                  Account Status:{" "}
                  <span
                    style={{ color: rider?.isVerified === 1 ? "green" : "red" }}
                  >
                    {rider?.isVerified === 1 ? "Verified" : "Pending"}
                  </span>
                </p>
                <div>
                  <h1>Hii {rider?.name}!</h1>
                </div>
                <p>
                  Your account has been created! Please provide the documents
                  and information listed below to start driving with us. Once
                  your account is approved for riding, simply open your
                  Sprinterwave application and click the "Rider" button that
                  will appear, allowing you to access the rider features.
                </p>

                {rider?.application_error_note && (
                  <div className={classes.error_reasons}>
                    <p>{rider?.application_error_note}</p>
                  </div>
                )}

                <br />
                <br />
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Desired Location *</h2>
                  <p>Please input your desired location</p>
                </div>
                <div className={classes.iput_container}>
                  <div>
                    <input
                      type="text"
                      name="dirvingLocation"
                      value={enteredLocation}
                      id="dirvingLocation"
                      placeholder="Enter dirvingLocation"
                      onChange={handleChangeText}
                    />
                    {/* <p style={{color: rider?.review === "approved"?  "green": rider?.review === "in review"? "orange": "red"}} className={classes.status_txt}>In review</p> */}
                  </div>
                </div>
              </div>
              <div className={classes.input_wrapper}>
                <div>
                  <h2>Choose your Transport Type *</h2>
                  <p>
                    Select your preferred mode of transportation: vehicle,
                    motorcycle, or bicycle.{" "}
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <div>
                    {rider?.transportType && (
                      <p>Transport type: {rider?.transportType}</p>
                    )}
                    <select
                      id="transport"
                      name="transport"
                      onChange={onSelectHandler}
                    >
                      <option value="vehicle">Vehicle</option>
                      <option value="motorcycle">Motorcycle</option>
                      <option value="bicycle">Bicycle</option>
                    </select>

                    {/* <p style={{color: rider?.review === "approved"?  "green": rider?.review === "in review"? "orange": "red"}} className={classes.status_txt}>In review</p> */}
                  </div>
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Profile Picture *</h2>
                  <p>
                    Please provide a recent, clear copy of your photo to ensure
                    your information is current and accurate.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="driverProfilePhoto"
                    id="driverProfilePhoto"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="driverProfilePhoto">
                      {rider?.driverProfilePhotoStatus === "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() +
                            rider?.driverProfilePhoto
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div
                          className={classes.upload_btn}
                          id="driverProfilePhoto"
                        >
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.driverProfilePhotoStatus === "approved"
                            ? "green"
                            : rider?.driverProfilePhotoStatus === "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.driverProfilePhotoStatus!}
                    </p>
                  </div>

                  {rider?.driverProfilePhotoStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler(
                            "driverProfilePhoto",
                            rider?.driverProfilePhoto
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Driver's License *</h2>
                  <p>
                    Please submit a copy of your valid driver's license to
                    comply with our driving requirements and verify your legal
                    ability to operate a vehicle safely and responsibly.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="driverLicense"
                    id="driverLicense"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="driverLicense">
                      {rider?.driverLicenseStatus === "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() + rider?.driverLicense
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div className={classes.upload_btn} id="driverLicense">
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.driverLicenseStatus === "approved"
                            ? "green"
                            : rider?.driverLicenseStatus === "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.driverLicenseStatus!}
                    </p>
                  </div>

                  {rider?.driverLicenseStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler("driverLicense", rider?.driverLicense)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Proof of Car Ownership Certificate *</h2>
                  <p>
                    Please provide a current Proof of Car Ownership Certificate
                    to confirm you are the legal owner of the vehicle you will
                    use for driving with us, including details like your name
                    and vehicle registration number.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="carOwnershipCertificate"
                    id="carOwnershipCertificate"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="carOwnershipCertificate">
                      {rider?.carOwnershipCertificateStatus === "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() +
                            rider?.carOwnershipCertificate
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div
                          className={classes.upload_btn}
                          id="carOwnershipCertificate"
                        >
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.carOwnershipCertificateStatus === "approved"
                            ? "green"
                            : rider?.carOwnershipCertificateStatus ===
                              "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.carOwnershipCertificateStatus!}
                    </p>
                  </div>

                  {rider?.carOwnershipCertificateStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler(
                            "carOwnershipCertificate",
                            rider?.carOwnershipCertificate
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Valid Government ID *</h2>
                  <p>
                    Please submit a current, clearly visible copy of your
                    government-issued ID to verify your identity and employment
                    eligibility.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="governmentID"
                    id="governmentID"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="governmentID">
                      {rider?.governmentIDStatus === "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() + rider?.governmentID
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div className={classes.upload_btn} id="governmentID">
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.governmentIDStatus === "approved"
                            ? "green"
                            : rider?.governmentIDStatus === "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.governmentIDStatus!}
                    </p>
                  </div>

                  {rider?.governmentIDStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler("governmentID", rider?.governmentID)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Certificate of Roadworthiness *</h2>
                  <p>
                    Please provide a valid Certificate of Roadworthiness to
                    confirm that your vehicle meets all safety standards and is
                    fit for road use, ensuring everyone's safety.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="roadworthinessCertificate"
                    id="roadworthinessCertificate"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="roadworthinessCertificate">
                      {rider?.roadworthinessCertificateStatus ===
                      "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() +
                            rider?.roadworthinessCertificate
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div
                          className={classes.upload_btn}
                          id="roadworthinessCertificate"
                        >
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.roadworthinessCertificateStatus === "approved"
                            ? "green"
                            : rider?.roadworthinessCertificateStatus ===
                              "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.roadworthinessCertificateStatus!}
                    </p>
                  </div>

                  {rider?.roadworthinessCertificateStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler(
                            "roadworthinessCertificate",
                            rider?.roadworthinessCertificate
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.input_wrapper}>
                <div>
                  <h2>Hackney Permit *</h2>
                  <p>
                    Please submit a current, legible copy of your Hackney
                    Permit, required for legally operating a taxi or cab service
                    and authorizing you to transport passengers for hire.
                  </p>
                </div>
                <div className={classes.iput_container}>
                  <input
                    type="file"
                    accept="image/*"
                    name="hackneyPermit"
                    id="hackneyPermit"
                    onChange={handleChange}
                  />

                  <div>
                    <label htmlFor="hackneyPermit">
                      {rider?.hackneyPermitStatus === "in review" ? (
                        <a
                          target={"_blank"}
                          href={
                            RiderImageURLDocumentPath() + rider?.hackneyPermit
                          }
                        >
                          view image
                        </a>
                      ) : (
                        <div className={classes.upload_btn} id="hackneyPermit">
                          <FaImage color="white" />
                          <span> select</span>
                        </div>
                      )}
                    </label>
                    <p
                      style={{
                        color:
                          rider?.hackneyPermitStatus === "approved"
                            ? "green"
                            : rider?.hackneyPermitStatus === "in review"
                            ? "orange"
                            : "red",
                      }}
                      className={classes.status_txt}
                    >
                      {rider?.hackneyPermitStatus!}
                    </p>
                  </div>

                  {rider?.hackneyPermitStatus === "in review" && (
                    <div>
                      <BsTrash
                        size={14}
                        onClick={() =>
                          onDeleteHandler("hackneyPermit", rider?.hackneyPermit)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showPreview && (
              <div className={classes.preview_wrapper}>
                <div className={classes.prv_section}>
                  <div>
                    <div className={classes.head}>
                      <h1>Preview</h1>
                      <FaTimes onClick={() => setShowPreview(false)} />
                    </div>
                    <div>
                      <img id="" src={image} alt="" />
                    </div>
                  </div>
                  <div>
                    {isLoading ? (
                      <button>Please wait...</button>
                    ) : (
                      <button onClick={onUploadHandler}>Upload</button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {rider?.isVerified === 1 && (
            <div className={classes.verified_container}>
              <div className={classes.verified_wrapper}>
                <h1>Welcome Aboard – Your Rider Account is Verified!</h1>

                <br />

                <p>
                  Hi there,
                  <br />
                  <br />
                  We’re pleased to inform you that your rider account has been
                  successfully verified! You are now ready to hit the road with
                  the SprinterWave app.
                  <br />
                  <br />
                  To get started, simply log in to the SprinterWave app on your
                  mobile device. Once logged in, you can immediately begin
                  accepting ride requests and explore various features designed
                  to make your driving experience as smooth and enjoyable as
                  possible.
                  <br />
                  <br />
                  If you have any questions or need assistance, feel free to
                  reach out to our support team. We're here to help!
                  <br />
                  <br />
                  Happy riding and welcome to the SprinterWave community!
                  <br />
                  <br />
                  Best regards,
                  <br />
                  The SprinterWave Team.
                </p>

                <div className={classes.logout_container}>
                  <Link to={"/rider"}>
                    <button>
                      <FaPowerOff /> <span>Log out</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </AuthWrapper>
  );
}
