export function ImageForEvent(){
    return 'http://localhost:8080/images/event/'
    // return 'https://server.unioncommunitygesellschaft.org/images/event/'
}

export function RiderImageURLDocumentPath(){
    return 'http://localhost:8080/images/rider_documents/'
    // return 'https://server.unioncommunitygesellschaft.org/images/rider_documents/'
}

export function URLAvatar(){
    return 'http://localhost:8080/images/avatar/'
    // return 'https://server.unioncommunitygesellschaft.org/images/avatar/'
}
export function baseUrl(){
    return 'http://localhost:8080'
    // return 'https://server.unioncommunitygesellschaft.org'
}