import React from "react";
import { BsWallet } from "react-icons/bs";
import { MdEvent } from "react-icons/md";
import classes from "./ColsStyles.module.css";
function Col1_about() {
  return (
    <div className={classes.main + " " + classes.about_main}>
      <div className={classes.data + " " + classes.about_data}>
        <h1>About Us</h1>

        <p>
          {" "}
          Welcome to Sprinterwave, your all-in-one solution for managing bills,
          purchasing airtime, data, and more. Our platform is designed to
          simplify your financial management while providing convenient access
          to a variety of services.
        </p>

        <p>
          We are committed to providing a comprehensive and efficient app that
          simplifies the way you manage bills, purchase airtime, data, event
          tickets, and more. Our mission is to empower users with a secure and
          user-friendly platform that caters to their diverse financial and
          logistical requirements.
        </p>

        <div className={classes.flex_btm_box}>
          <div className={classes.box_1}>
            {/* <i>
              <MdEvent />
            </i> */}
            <h3>Who We Are</h3>
            <p>
              At Sprinterwave, we are dedicated to offering a seamless and
              user-friendly experience for all your financial and logistical
              needs. Our goal is to empower you with a comprehensive app that
              streamlines bill management and offers secure, hassle-free ticket
              purchasing.
            </p>
          </div>
        </div>

        <div className={classes.flex_btm_box}>
          <div className={classes.box_1}>
            {/* <i>
              <BsWallet />
            </i> */}
            <h3>What We Offer</h3>
            <p>
              Our app provides a multifaceted approach to help you take control
              of your finances and daily logistics. From bill management to
              securing event tickets, Sprinterwave is your go-to platform for
              effortless control and management. We understand the importance of
              simplicity, and that's why our user-friendly interface ensures a
              smooth navigation experience for all users.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.image_wrapper + " " +classes.show_img}>
       <div>
       <img src="/me.png" alt="" className={classes.logo_large} />
        <a href="https://gabrieldelight.me/" target={"_blank"} >
          <h3>
            <b>Gabriel Delight</b> Founder / CEO
          </h3>
        </a>
       </div>
      </div>
    </div>
  );
}

export default Col1_about;
