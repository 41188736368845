import React from "react";
import classes from "./Col4.module.css";
function Col4() {
  return (
    <div className={classes.conatiner}>
    <div className={classes.wrapper}>
      <h1>

        Enjoy seamless payments <br />  and effortless booking through  <br />  our user-friendly app.
        <br /> 
        Download Now!
      </h1>
      <p>Download our app for free today</p>
      <div className={classes.download_}>
        <img src="/images/appstore_.webp" alt="" />
        <img src="/images/playstore_.webp" alt="" />
      </div>
    </div>
    </div>
  );
}

export default Col4;
