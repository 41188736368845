import React from "react";
import { BsWallet } from "react-icons/bs";
import { MdEvent } from "react-icons/md";
import classes from "./ColsStyles.module.css";
function Col1() {
  return (
    <div className={classes.main}>
      <div className={classes.data}>
        <h1>Our aim is to provide an incomparable experience</h1>

        <p>
          Event booking and bill payments are our top priorities, and we aim for
          everyone to enjoy using Sprinterwave. We strive to create an
          experience that resonates with everyone, making Sprinterwave a part of
          their daily activities.{" "}
        </p>

        <div className={classes.flex_btm_box}>
          <div className={classes.box_1}>
            <i>
              <MdEvent />
            </i>
            <h3>Anytime Booking</h3>
            <p>
              Organizing an event and event booking within Sprinterwave is the
              ultimate choice, offering speed, simplicity, and high-level
              security integration.
            </p>
          </div>

          <div className={classes.box_1}>
            <i>
              <BsWallet />
            </i>
            <h3>Anytime Transaction</h3>
            <p>
              Managing bill payments within Sprinterwave is top-tier, combining
              speed, simplicity, and robust security measures.
            </p>
          </div>
        </div>
      </div>
      <div className={classes.image_wrapper}>
        <img
          src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/MobileApp02.png"
          alt=""
        />
        <img
          src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Dashboard.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default Col1;
