import React, { useState } from "react";
import classes from "./HeaderStyles.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import MobileNav from "../MobileNav/MobileNav";
const Header = () => {
  const navigate = useNavigate();
  const [navisToggledm, setNavIsToggled] = useState<boolean>(false);

  const toggleNavBar = () => {
    setNavIsToggled(!navisToggledm);
  };

  return (
    <>
      <header className={classes.header}>
        <div className={classes.logo} onClick={() => navigate("/")}>
          <img className={classes.logo} src="/sprinter.png" alt="" />
          <h3>Sprinterwave</h3>
        </div>

        <nav className={classes.nav}>
          <ul>
            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/" ? classes.active : ""
                }
              >
                <Link to={"/"}>Home</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/about" ? classes.active : ""
                }
              >
                <Link to={"/about"}>About</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/rider" ? classes.active : ""
                }
              >
                <Link to={"/rider"}>Rider Application</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/blog" ? classes.active : ""
                }
              >
                <Link to={"/blog"}>Blog</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/privacy-policy" ? classes.active : ""
                }
              >
                <Link to={"/privacy-policy"}>Privacy Policy</Link>
              </span>
            </li>
            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/terms-and-conditions" ? classes.active : ""
                }
              >
                <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
              <span
                className={
                  window.location.pathname === "/contact" ? classes.active : ""
                }
              >
                <Link to={"/contact"}>Contact</Link>
              </span>
            </li>

            <li className={classes.desktop_only}>
           <a target={"_blank"} href="https://play.google.com/store/apps/details?id=com.wb.goog.got.conquest&hl=en&gl=US">
                <button className={classes.download_btn}>Download</button>
           </a>
     
            </li>

            <li className={classes.mobile_only}>
              <i onClick={toggleNavBar}>
                <GiHamburgerMenu style={{color: "white", fontSize: 25}} />
              </i>{" "}
            </li>
          </ul>
        </nav>
      </header>
      <div className={classes.mobile_hamburger}>
        <GiHamburgerMenu onClick={toggleNavBar} />
      </div>

      {navisToggledm && <MobileNav onCLosehandler={toggleNavBar} />}
    </>
  );
};

export default Header;
