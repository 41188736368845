import React from "react";
import classes from "./Styles/Section1styles.module.css";
const Section1 = () => {
  return (
    <section className={classes.section}>
      <div className={classes.details}>
        <div className={classes.name}>
          <h1>Fostering Connections, Empowering Transactions </h1>
        </div>

        <div className={classes.stack}>
          <p>
          The ultimate app for bills, including airtime, data, and more! Manage finances, secure event tickets, and streamline logistics—all in one easy-to-use platform. Take control effortlessly with our multifaceted, user-friendly app.
          </p>

          <div className={classes.download_}>
            <img src="/images/appstore_.webp" alt="" />
            <img src="/images/playstore_.webp" alt="" />
          </div>
        </div>
        
      </div>

      <div className={classes.left_image}>
        <img src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/MobileApp02.png" alt="" />
      </div>
    </section>
  );
};

export default Section1;
