import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Col1 from "../components/cols/Col1";
import Col2 from "../components/cols/Col2";
import Col3 from "../components/cols/Col3";
import Col4 from "../components/cols/Col4";
import NewsLetter from "../components/cols/NewsLetter";
import StatsCounter from "../components/cols/StatsCounter";
import Section1 from "../components/Sections/Section1";
import Body from "../components/Wrapper/Body";
import { Helmet } from "react-helmet";

const Home: React.FC = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <Body>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Empowering Simplicity, Enriching Transactions </title>
        <meta
          name="description"
          content="The ultimate African app for easy transactions, bill payments, event tickets, and logistics. Manage finances and streamline tasks effortlessly!"
        />
        <meta
          name="keywords"
          content="Transaction processing, Bill payment, Ticket booking, Logistics solutions, Financial management, Mobile payments, Event tickets, Transport services, Mobile app, Payment app, Bill management, Logistics app, Financial transactions, Ticket reservations, Digital payments, Mobile commerce, Convenience app, Utility payments, Event planning"
        ></meta>

        <link rel="canonical" href={"https://sprinterwave.com/"} />
      </Helmet>

      <Section1 />

      {/* Cols */}

      <Col1 />
      <br></br>

      <StatsCounter />
      <br></br>
      <br></br>
      <br></br>
      <Col3 />
      <br></br>
      <br></br>
      <br></br>
      <Col4 />
      <br></br>
      <br></br>
      <Col2 />
      <NewsLetter />
    </Body>
  );
};

export default Home;
