import React from "react";
import { GiTicket } from "react-icons/gi";
import { MdEvent, MdPayment } from "react-icons/md";
import classes from "./ColsStyles.module.css";
function Col2() {
  return (
    <div className={classes.main}>
      <div className={classes.data}>
        <h1>We aim to deliver an unparalleled update for our users.</h1>

        <p>
          Our goal is to provide an unmatched update experience for our users,
          setting a new standard in delivering excellence and innovation.
        </p>

        <div className={classes.flex_btm_box + " " + classes.col_formart}>
          <div className={classes.box_1 + " " + classes.row_format}>
            <div className={classes.icon_wrapper}>
              <i>
                <MdEvent />
              </i>
            </div>

            <div>
              <h3>Smart Event Suggestions</h3>
              <p>
                Discover tailored event suggestions that match your interests
                and preferences. Our app uses smart algorithms to recommend
                events you'll love.
              </p>
            </div>
          </div>

          <div className={classes.box_1 + " " + classes.row_format}>
            <div className={classes.icon_wrapper}>
              <i>
                <GiTicket />
              </i>
            </div>
            <div>
              <h3>Secure Ticket Purchases</h3>
              <p>
                Purchase event tickets with confidence. Our app ensures secure
                transactions, safeguarding your payment details with top-notch
                encryption.
              </p>
            </div>
          </div>

          <div className={classes.box_1 + " " + classes.row_format}>
            <div className={classes.icon_wrapper}>
              <i>
                <MdPayment />
              </i>
            </div>
            <div>
              <h3>Seamless Payment Integration</h3>
              <p>
                Effortlessly pay for event tickets. Our app integrates with
                various payment methods for a hassle-free and swift transaction
                experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.image_wrapper + " " + classes.image_wrapper2}>
        <img
          src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/Blog06.jpg"
          alt=""
        />
      </div>
    </div>
  );
}

export default Col2;
