import React from "react";
import { BodyWrapper } from "../../interface/Interface";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const Body: React.FC<BodyWrapper> = ({ children }) => {
  
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Body;
