import React from "react";
import classes from "./BlogPagesTitleBox.module.css";

function BlogPagesTitleBox() {
  return (
    <div className={classes.conatiner}>
      <div className={classes.wrapper}>
        {" "}
        <p>Blog</p>
        <h1>Our Blog Posts</h1>
      </div>
    </div>
  );
}

export default BlogPagesTitleBox;
