import React from "react";
import classes from "./Col2Styles.module.css";
function Col3() {
  return (
    <section className={classes.section}>
      <div className={classes.top_styles}>
        <h1>A user-friendly and straightforward experience</h1>
        <h1 style={{ color: "#1498dd" }}>with Sprinterwave</h1>
        <p>
        Discover the high-energy in-app activities that position Sprinterwave as the top choice for users
        </p>
      </div>


      <div className={classes.main}>
        <div className={classes.left_section}>
          <div className={classes.text_wrapper}>
            <div>
              <h3>Instant Notifications </h3>
              <p>
              Stay in the loop in real-time! Instant notifications keep you updated, ensuring you never miss an important update or event.
              </p>
            </div>

            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>
          </div>

          <div className={classes.text_wrapper}>
            <div>
              <h3>Payment Statements</h3>
              <p>
              Stay informed effortlessly with clear and concise payment statements. Keep track of your transactions hassle-free.
              </p>
            </div>
            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>
          </div>

          <div className={classes.text_wrapper}>
            <div>
              <h3>24/7 Contact Support</h3>
              <p>
              Get help anytime, anywhere! Our round-the-clock support is here to assist you whenever you need
              </p>
            </div>
            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.middle_section}>
          <img
            src="https://advanture.icu/bden/wp-content/uploads/sites/18/2023/04/MobileApp03.png"
            alt=""
          />
        </div>

        <div className={classes.right_section}>
          <div className={classes.text_wrapper}>
            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>

            <div>
              <h3>Secure Transaction</h3>
              <p>Your transactions are shielded for peace of mind. Experience worry-free, secure payments every time.</p>
            </div>
          </div>

          <div className={classes.text_wrapper}>
            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>
            <div>
              <h3>Effortlessly Manage Your Account</h3>
              <p>
              Take control effortlessly! Manage your account seamlessly with simple, user-friendly interface.
              </p>
            </div>
          </div>

          <div className={classes.text_wrapper}>
            <div className={classes.dot_container}>
              <div className={classes.dot_wrapper}>
                <div className={classes.round_dot}></div>
              </div>
            </div>
            <div>
              <h3>Secured With 2FA</h3>
              <p>
              Double the security, double the peace of mind. Your account is fortified with two-factor authentication for added protection.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Col3;
