import React, { useEffect, useState, useRef } from "react";
import classes from "./StatsCounter.module.css";
function StatsCounter() {


  const [counter1, setCOunter1] = useState(0);
  const [counter2, setCOunter2] = useState(0);
  const [counter3, setCOunter3] = useState(0);
  const [counter4, setCOunter4] = useState(0);




  const targetRef = useRef<any>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          let c_1 = 0;
          let c_2 = 0;
          let c_3 = 0;
          let c_4 = 0;
      
          let id = setInterval(() => {
            if (c_1 < 75) setCOunter1(c_1++);
            if (c_2 < 85) setCOunter2(c_2++);
            if (c_3 < 94) setCOunter3(c_3++);
            if (c_4 < 99) setCOunter4(c_4++);
          }, 10);
      
          return () => clearTimeout(id);
        }
      }
    });

    observer.observe(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [targetRef]);


  return (
    <div className={classes.container} ref={targetRef}>
      <h1 className={classes.title}>The statistics are self-explanatory.




</h1>

      <div className={classes.counter_wrapper}>
        <div>
          <h1>{counter1}%</h1>
          <p>Active users</p>
        </div>
        <div>
          <h1>{counter2}%</h1>
          <p>Monthly Bookings</p>
        </div>
        <div>
          <h1>{counter3}%</h1>
          <p>Bills review</p>
        </div>
        <div>
          <h1>{counter4}%</h1>
          <p>Weekly subscription</p>
        </div>
      </div>
    </div>
  );
}

export default StatsCounter;
