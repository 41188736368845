import React, { useState } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { faq } from "../../Data/Faq";

import classes from "./FaqStyles.module.css";
function Faq() {
  const [indexValue, setIndexValue] = useState<number | null>(null);

  const onToggleClickhandler = (index: number) => {
    if (indexValue === index) return setIndexValue(null);
    setIndexValue(index);
  };

  return (
    <main className={classes.main}>
      <div className={classes.left_section}>
        <h2>Frequently Asked Questions</h2>

        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae{" "}
        </p>

        {faq.map(({ description, title }, index) => {
          return (
            <div className={classes.list_wrapper} key={index}>
              <div
                className={classes.title_seciton}
                style={{
                  backgroundColor: indexValue === index ? "#1498dd" : "#414141",
                }}
                onClick={() => onToggleClickhandler(index)}
              >
                <h3>{title}</h3>

                <i>
                  {indexValue === index ? <BsChevronUp /> : <BsChevronDown />}
                </i>
              </div>
              {indexValue === index && (
                <div className={classes.description_section}>
                  <p>{description}</p>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className={classes.right_section}>
        <div className={classes.subscribe}>
          <h3>Don't Find What You're Looking For?</h3>
          <p>Send us a query Lorem ipsum dolor sit amet, consectetur</p>

          <a href="#contact_us">
            <button>Contact Us</button>
          </a>
        </div>
      </div>
    </main>
  );
}

export default Faq;
